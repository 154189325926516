var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-title', {
    attrs: {
      "tit": "시설운영지원",
      "sc": "사각지대에 놓인 지역 주민들의 삶의 질 향상을 위해 지역사회 내 사회복지 시설의 신규 유치 및 운영을 지원하고, 최근 사회복지 동향에 대한 전문 정보의 공유를 통해 사회복지의 현대화·전문화에 기여함으로써 사회복지 활동 영역의 기반확대, 사회적 위상 제고"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }