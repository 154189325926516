var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    staticClass: "v-tabs--primary tab--block",
    attrs: {
      "hide-slider": "",
      "height": "auto"
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/business-domestic/operate/care"
    }
  }, [_vm._v("보육/아동돌봄")]), _c('v-tab', {
    attrs: {
      "to": "/business-domestic/operate/child"
    }
  }, [_vm._v("아동보호")]), _c('v-tab', {
    attrs: {
      "to": "/business-domestic/operate/middle"
    }
  }, [_vm._v("중장년 복지")]), _c('v-tab', {
    attrs: {
      "to": "/business-domestic/operate/disabled"
    }
  }, [_vm._v("장애인복지")]), _c('v-tab', {
    attrs: {
      "to": "/business-domestic/operate/senile"
    }
  }, [_vm._v("노인복지")]), _c('v-tab', {
    attrs: {
      "to": "/business-domestic/operate/country"
    }
  }, [_vm._v("지역복지")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }