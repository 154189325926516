var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sub-visual', {
    attrs: {
      "sh": "국내사업",
      "tabActive": "시설운영지원",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }