var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-head"
  }, [_c('div', {
    staticClass: "sub-visual",
    class: {
      'sub-visual--sub-tab': !_vm.subTabNone
    }
  }, [_c('div', {
    staticClass: "sub-visual__inner"
  }, [_c('v-container', [_c('div', {
    staticClass: "sub-visual__tit-wrap"
  }, [_c('h2', {
    staticClass: "sub-visual__tit"
  }, [_vm._v(_vm._s(_vm.sh))])])])], 1), _c('div', {
    staticClass: "sub-visual__bg",
    style: 'background-image:url(' + _vm.bg + ');'
  })]), !_vm.subTabNone ? _c('div', {
    staticClass: "sub-head__bottom"
  }, [_c('v-container', [_c('client-gnb', {
    attrs: {
      "className": "sub-tab-wrap",
      "tabActive": _vm.tabActive,
      "sh": _vm.sh
    }
  })], 1)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }