import { render, staticRenderFns } from "./tab-business-domestic.vue?vue&type=template&id=e7f37184&scoped=true&"
import script from "./tab-business-domestic.vue?vue&type=script&lang=js&"
export * from "./tab-business-domestic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7f37184",
  null
  
)

export default component.exports