<template>
    <client-page>

        <sub-visual-business-domestic />

        <section class="section">
            <v-container>

                <page-title-operate />

                <tab-business-domestic />

                <business-item imageGrid="4" tit="동탄어울림장애인주간보호센터" file="dtd" url="">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 장애인주간보호시설
                        </li>
                        <li class="dot-text">
                            소재지 : 경기도 화성시 동탄대로시범길 133
                        </li>
                        <li class="dot-text">
                            설립일 : 2018.4.11
                        </li>
                        <li class="dot-text">
                            종사자 : 4명
                        </li>
                        <li class="dot-text">
                            시설장 : 최성규
                        </li>
                        <li class="dot-text">
                            주요사업 : 발달장애인 사례관리, 일상생활지원, 신체활동지원, 여가활동, 지역사회통합지원, 자립능력향상, 가족지원, 특별활동
                        </li>
                    </ul>
                </business-item> 
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualBusinessDomestic from "@/components/client/sub/sub-visual-business-domestic.vue";
import TabBusinessDomestic from '@/components/client/sub/tab-business-domestic.vue';
import PageTitleOperate from '@/components/client/sub/page-title-operate.vue';
import BusinessItem from '@/components/client/sub/business-item.vue';
export default {
    components: {
        ClientPage,
        SubVisualBusinessDomestic,
        TabBusinessDomestic,
        PageTitleOperate,
        BusinessItem,
    },
};
</script>