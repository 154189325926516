<template>
    <div class="mb-40 mb-lg-60 text-center">
        <h3 class="tit tit--md line-height-1 mb-8 mb-lg-16">
            {{tit}}
        </h3>
        <p v-if="sc" class="page-text page-text--lg line-height-15 word-keep-all">
            {{sc}}
        </p>
    </div>
</template>

<script>

export default{
    props : {
        tit : {type :String, default : ""},
        sc: {type :String, default : ""},
    },
	components: {
	},
}
</script>
<style lang="scss" scoped>
.form-row{
    border-bottom: 1px solid #ececec;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>