var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-section business-item"
  }, [_c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-row', {
    attrs: {
      "align-md": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")])]), _c('v-col', {
    staticClass: "pt-10 pt-md-0 pl-md-16",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_vm.url ? _c('v-btn', {
    attrs: {
      "href": _vm.url,
      "target": "_blank",
      "color": "primary",
      "outlined": ""
    }
  }, [_c('span', [_vm._v("홈페이지")]), _c('v-icon', {
    staticClass: "font-size-18 ml-4"
  }, [_vm._v("mdi-chevron-right")])], 1) : _vm._e()], 1)], 1), _vm.sc ? _c('p', {
    staticClass: "page-text page-text--lg grey-1--text line-height-15 mt-8 mt-lg-16"
  }, [_vm._v(" " + _vm._s(_vm.sc) + " ")]) : _vm._e(), _c('v-divider', {
    staticClass: "my-12 my-lg-16"
  }), _vm._t("default")], 2), _vm.imageGrid ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": '/images/sub/business/' + _vm.file + '-img1.jpg'
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": '/images/sub/business/' + _vm.file + '-img2.jpg'
    }
  })], 1), _vm.imageGrid != '2' ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": '/images/sub/business/' + _vm.file + '-img3.jpg'
    }
  })], 1) : _vm._e(), _vm.imageGrid != '2' ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": '/images/sub/business/' + _vm.file + '-img4.jpg'
    }
  })], 1) : _vm._e()], 1) : _c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": '/images/sub/business/' + _vm.file + '-img.jpg'
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }