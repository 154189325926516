var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-40 mb-lg-60 text-center"
  }, [_c('h3', {
    staticClass: "tit tit--md line-height-1 mb-8 mb-lg-16"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]), _vm.sc ? _c('p', {
    staticClass: "page-text page-text--lg line-height-15 word-keep-all"
  }, [_vm._v(" " + _vm._s(_vm.sc) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }