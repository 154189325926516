<template>
    <v-tabs hide-slider height="auto" class="v-tabs--primary tab--block">
        <v-tab to="/business-domestic/operate/care">보육/아동돌봄</v-tab>
        <v-tab to="/business-domestic/operate/child">아동보호</v-tab>
        <v-tab to="/business-domestic/operate/middle">중장년 복지</v-tab>
        <v-tab to="/business-domestic/operate/disabled">장애인복지</v-tab>
        <v-tab to="/business-domestic/operate/senile">노인복지</v-tab>
        <v-tab to="/business-domestic/operate/country">지역복지</v-tab>
    </v-tabs>
</template>

<script>
export default {
    components: {
    },
};
</script>

<style lang="scss" scoped>
</style>