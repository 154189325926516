<template>
    <sub-visual
    sh="국내사업"
    tabActive="시설운영지원"
    bg="/images/sub/visual/sv2.jpg">
    </sub-visual>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
</style>