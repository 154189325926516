<template>
    <div class="sub-head">
        <div class="sub-visual" :class="{ 'sub-visual--sub-tab': !subTabNone }">
            <div class="sub-visual__inner">
                <v-container>
                    <div class="sub-visual__tit-wrap">
                        <h2 class="sub-visual__tit">{{ sh }}</h2>
                    </div>
                </v-container>
            </div>
            <div class="sub-visual__bg" :style="'background-image:url(' + bg + ');'"></div>
        </div>
        
        <div class="sub-head__bottom" v-if="!subTabNone">
            <v-container>
                <client-gnb className="sub-tab-wrap" :tabActive="tabActive" :sh="sh"></client-gnb>
            </v-container>
        </div>
    </div>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";

export default {
    props: {
        sh: { type: String, default: "" },
        bg: { type: String, default: "" },
        tabActive: { type: String, default: "" },
        subTabNone: { type: Boolean, default: false },
    },
    components: {
        ClientGnb,
    },
    data: () => {
        return {};
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.sub-head{
    position: relative;
}
/* sub-visual */
.sub-visual {
    position:relative;
    height: 180px;
}
.sub-visual__tit {
    font-size: 3rem;
    font-weight:700;
    color:#fff;
}
.sub-visual__bg {
    position: absolute;
    top:0;
    left: 50%;
    z-index: 0;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.sub-visual__inner{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
.sub-visual__inner .container{
    height: 100%;
}
.sub-visual__tit-wrap{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 260px;
    }
    .sub-visual__tit {
        font-size: 4rem;
    }
    .sub-visual--sub-tab{
        padding-bottom: 48px;
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 380px;
    }
    .sub-visual__tit {
        font-size: 4.8rem;
    }
    .sub-visual--sub-tab{
        padding-bottom: 60px;
    }
}
@media (min-width: 1200px) {
}

/* sub-tab-wrap */
.sub-head__bottom{
    width: 100%;
    position: relative;
    background-color: #6d6e6d;
}
.sub-tab-wrap{
    width: 100%;
}
.sub-head__bottom .container{
    max-width: var(--container);
    padding: 0;
}
@media (min-width:768px){
    .sub-head__bottom{
        background-color: rgba(0, 0, 0, 0.5);
    }
}
@media (min-width:1024px){
}

</style>
