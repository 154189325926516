<template>
    <section class="page-section business-item">
        <div class="mb-20 mb-lg-40">
            <v-row align-md="center" no-gutters>
                <v-col cols="12" md="auto">
                    <h4 class="tit tit--sm line-height-1">
                        {{tit}}
                    </h4> 
                </v-col>
                <v-col cols="12" md="auto" class="pt-10 pt-md-0 pl-md-16">                    
                    <v-btn v-if="url" :href="url" target="_blank" color="primary" outlined>
                        <span>홈페이지</span><v-icon class="font-size-18 ml-4">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <p v-if="sc" class="page-text page-text--lg grey-1--text line-height-15 mt-8 mt-lg-16">
                {{sc}}
            </p>
            <v-divider class="my-12 my-lg-16" />
            <slot />
        </div>
        <v-row v-if="imageGrid">
            <v-col cols="6">
                <v-img :src="'/images/sub/business/'+file+'-img1.jpg'" class="w-100"></v-img>
            </v-col>
            <v-col cols="6">
                <v-img :src="'/images/sub/business/'+file+'-img2.jpg'" class="w-100"></v-img>
            </v-col>
            <v-col cols="6" v-if="imageGrid != '2'">
                <v-img :src="'/images/sub/business/'+file+'-img3.jpg'" class="w-100"></v-img>
            </v-col>
            <v-col cols="6" v-if="imageGrid != '2'">
                <v-img :src="'/images/sub/business/'+file+'-img4.jpg'" class="w-100"></v-img>
            </v-col>
        </v-row>
        <v-img v-else :src="'/images/sub/business/'+file+'-img.jpg'" class="w-100"></v-img>
    </section>
</template>

<script>

export default {
    props: {
        tit: { type: String, default: "" },
        sc: { type: String, default: "" },
        file: { type: String, default: "" },
        imageGrid: { type: String, default: "" },
        url: {type: String, default: ""}
    },
    components: {
    },
    data: () => {
        return {};
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default {
    padding: 0 10px;
}
</style>