var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-business-domestic'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('page-title-operate'), _c('tab-business-domestic'), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "동탄어울림장애인주간보호센터",
      "file": "dtd",
      "url": ""
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 장애인주간보호시설 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 경기도 화성시 동탄대로시범길 133 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 설립일 : 2018.4.11 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 4명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 최성규 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 발달장애인 사례관리, 일상생활지원, 신체활동지원, 여가활동, 지역사회통합지원, 자립능력향상, 가족지원, 특별활동 ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }